import React from "react"
import { useEffect } from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import arrow_back from "../images/arrow_back.svg"
import arrow_right from "../images/arrow_right.svg"
import cover from "../images/sample_image.jpg"

const Post = ({ data }) => {
    const post = data.strapiPost

    const allPosts = data.allStrapiPost

    const theobj = data.strapiPost.anchorsJson !== null ? JSON.parse(data.strapiPost.anchorsJson.internal.content) : {}
    
    useEffect(() => {
        console.log(postContent)
      }, [])

    function addHost(str) {
        return str.replaceAll('src=\"/uploads', `src=\"https://strapi.internal.iamcore.io/uploads`)
      }
    
    function addReponsivenessToImg(str) {
        return str.replaceAll('<img ', `<img class="img-fluid"`)
    }
    
    let postContent = addReponsivenessToImg(addHost(post.content.data.childMarkdownRemark.html));    

    return (

        <Layout>
            <section className="blog-body">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-12">
                            <div className="back-link-wrapper">
                                <Link to="/blog" className="back-link"><img src={arrow_back} className="arrow-back" alt='more_icon' />Back to Blog</Link>
                            </div>
                        </div>
                        <div className="col-xl-10 offset-xl-1 col-12">
                            <div className="row ">
                                <div className="col-lg-9 col-12 left-column">
                                    <h1 className="text-center">{post.title}</h1>
                                    <span className="blog-date text-center">{post.date}</span>
                                    <GatsbyImage image={getImage(post.cover?.localFile)} className="img-fluid cover-img" alt=""/>

                                    <div dangerouslySetInnerHTML={{__html: postContent}} />

                                    <div className="banner">
                                        <h4>Secure your SaaS growth and scalability with<br/> battle-tested fast-start authorization service</h4>
                                        <Link to="https://cloud.iamcore.io/" className="btn btn-dark">START NOW</Link>
                                    </div>
                                    
                                </div>
                                <div className="col-lg-3 col-12 right-column">
                                    <span class="right-column-title">Table of contents</span>
                                    <div className="row">
                                        <div className="col-12">

                                            { theobj.length !== 0 ?
                                             
                                            Object.keys(theobj).map((key, index) => {
                                                let anchorStr = '#' + key
                                                return (
                                                    <Link to={anchorStr} className="similar-article-link">{theobj[key]}</Link>
                                                );
                                            })

                                            :
                                            <p>No table of contents for this article</p>

                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            { allPosts.length > 0 ? 
            <section className="last-articles">
                <div className="container">
                    <div className="row">
                        <div className="col-12 header-wrapper">
                            <div className="row align-items-center">
                                <div className="col-6">
                                    <h2>Last articles</h2>
                                </div>
                                <div className="col-6 text-end">
                                    <Link to="/blog" className="btn btn-outline">View all <img src={arrow_right} alt="arrow_icon"/></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">

                            {data.allStrapiPost.nodes.map((post, id) => (
                                <div className="col-md-4 col-sm-6 col-12 last-article-item" key={id}>
                                    <GatsbyImage image={getImage(post.cover?.localFile)} className="img-fluid" alt=""/>
                                    
                                    <Link to={`/blog/${post.slug}`} className="last-article-link">{post.title}</Link>
                                </div>
                            ))}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            :
            null
            }



        </Layout>

    )
}
export const Head = ({data}) => <Seo title={data.strapiPost.title} description={data.strapiPost.preview} image={"https://strapi.internal.iamcore.io" + data.strapiPost.cover.url}/>

export default Post

export const pageQuery = graphql`
    query ($slug: String) {
        strapiPost(slug: { eq: $slug }) {
            slug
            title
            preview
            date(formatString: "DD MMM YYYY")
            anchorsJson {
                internal {
                  content
                }
              }
            content {
              data {
                childMarkdownRemark {
                  html
                }
              }
            }
            cover {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          
        allStrapiPost(filter: {slug: {ne: $slug}}, sort: {date: ASC}, limit: 3) {
            nodes {
              slug
              title
              cover {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
    }
`